<template>
  <!-- 培训数据统计 -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <!-- 面包屑 -->
      <div class="framePage-title">
                <span>
                    <em>当前位置：</em>
                    <a href="javascript:;">运营中心</a>
                    <i>></i>
                    <a href="javascript:;" class="cur-a">培训数据统计</a>
                </span>
      </div>
      <!-- 主体区域 -->
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox">
            <div class="searchboxItem ci-full">
              <span title="年份" class="itemLabel">年度:</span>
              <el-date-picker v-model="searchForm.projectYear" type="year" value-format="yyyy" size="small" placeholder="选择年度" >
              </el-date-picker>
            </div>
            <div class="searchboxItem ci-full">
              <span title="培训大类" class="itemLabel">培训大类:</span>
              <el-select v-model="searchForm.trainCollectionId" placeholder="请选择" size="small" clearable>
                <el-option
                    v-for="item in trainCollectionList"
                    :key="item.collectionId"
                    :label="item.collectionName"
                    :value="item.collectionId">
                </el-option>
              </el-select>
            </div>
            <div class="searchboxItem ci-full">
              <span title="区域" class="itemLabel">区域:</span>
              <el-cascader
                  clearable
                  filterable
                  v-model="searchForm.areaId"
                  :options="areaTreeList"
                  :props="propsarea"
                  placeholder="请选择区域"
                  size="small"
              ></el-cascader>
            </div>
            <div class="btnBox">
              <el-button class="bgc-bv" @click="getData()">查询</el-button>
              <el-button class="bgc-bv" @click="exportDate()">导出</el-button>
            </div>
          </div>
        </div>
        <!-- 列表区域 -->
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <!--
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  fixed
                  :index="indexMethod"
              />
              -->
              <el-table-column
                  label="年份"
                  align="center"
                  prop="projectYear"
                  width="100"
              />
              <el-table-column
                  label="培训大类"
                  align="left"
                  show-overflow-tooltip
                  prop="collectionName"
                  width="200"
              />
              <el-table-column
                  label="省"
                  align="left"
                  prop="projectAreaProvince"
              />
              <el-table-column
                  label="市"
                  align="left"
                  prop="projectAreaCity"
              />
              <el-table-column
                  label="开班数量"
                  prop="projectNum"
              />
              <el-table-column
                  label="入班人数"
                  prop="projectPeople"
              />
              <el-table-column
                  label="认证人数"
                  prop="projectPeopleAuthentic"
              />
              <el-table-column
                  label="学习人数"
                  prop="projectPeopleStudy"
              />
              <el-table-column
                  label="学习完成人数"
                  prop="projectPeopleComplete"
              />
              <el-table-column
                  label="结业人数"
                  prop="projectPeopleFinish"
              />
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Empty from "@/components/Empty.vue";
import List from "@/mixins/List";
export default {
  name:"performance_trainingStatistics",
  components:{
    Empty,
  },
  mixins: [List],
  doNotInit:true,
  created(){
    this.gainTrainCollection();
    this.areaList();
  },
  data(){
    return{
      // 查询表单
      searchForm:{
        projectYear:"",        //年份
        trainCollectionId:"",  //培训大类id
        areaId:"",             //行政区划(省/市)
      },
      trainCollectionList: [], //培训大类列表
      areaTreeList: [],        //行政区划list
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        multiple: false,
        checkStrictly: true
      }
    }
  },
  methods:{
    getData(pageNum = 1) {
      const params = {};
      if (this.searchForm.projectYear) {
        params.projectYear = this.searchForm.projectYear
      }
      if (this.searchForm.trainCollectionId) {
        params.trainCollectionId = this.searchForm.trainCollectionId
      }
      if (this.searchForm.areaId) {
        params.areaId = this.searchForm.areaId
      }
      this.doFetch(
          {
            url: "/run/performance/training/list",
            params,
            pageNum,
          },
      );
    },
    //获取培训大类集合
    gainTrainCollection() {
      this.$post("/run/performance/train/collection/list", {})
          .then((ret) => {
            this.trainCollectionList = ret.data;
          })
          .catch((err) => {
            return;
          });
    },
    areaList() {
      this.$post("/sys/area/tree?areaLevel=2", null)
          .then((ret) => {
            this.areaTreeList = ret.data;
          })
          .catch((err) => {
            return;
          });
    },
    exportDate() {
      const params = {};
      if (this.searchForm.projectYear) {
        params.projectYear = this.searchForm.projectYear
      }
      if (this.searchForm.trainCollectionId) {
        params.trainCollectionId = this.searchForm.trainCollectionId
      }
      if (this.searchForm.areaId) {
        params.areaId = this.searchForm.areaId
      }
      this.$post("/run/performance/training/export", params)
          .then((ret) => {
            if (0 == ret.status) {
              window.open(ret.data.url);
              //按钮可用
            }
          })
          .catch((err) => {
            return;
          });
    }
  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.select-header {
  font-size: 14px;
  padding: 0 20px;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #606266;
  height: 34px;
  line-height: 34px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  cursor: pointer;
}
.framePage-body{
  .framePage-scroll{
    display: block !important;
    overflow: auto;

  }
}
// 对话框
.imgDialog{
  .detail{
    display: flex;
    .left{
      width: 150px;
      // height: 150px;
      flex-shrink: 0;
      .el-image{
        display: block;
        width: 100%;
        height: 100%;
      }
    }
    .right{
      margin-left: 20px;
      flex-grow: 1;
      .el-button{
        float: right;
      }
    }
  }
  .originalImg{
    margin-top: 20px;
    width: 100%;
    .el-image{
      display: block;
      width: 100%;
    }
  }
}
</style>